export default function imgBase64Download() {
    const elements = document.querySelectorAll('.img__base64-download-button');

    elements.forEach((element) => {
        element.addEventListener('click', (event) => {
            const targetId = event.target.dataset.target;

            if (typeof targetId !== 'string') {
                return;
            }

            const targetElement = document.getElementById(targetId);

            if (!(targetElement instanceof HTMLElement)) {
                return;
            }

            const {src} = targetElement;

            const a = document.createElement('a');

            a.download = 'qr.png';

            if (src.includes('image/svg+xml')) {
                a.download = 'qr.svg';
            }

            a.href = targetElement.src;

            a.click();
        });
    });
}
