export default function () {
    const input = document.querySelector("[name='channel_filter_input']");

    if (!input) {
        return;
    }

    input.addEventListener('change', (event) => {
        event.preventDefault();

        const url = new URL(window.location.href);

        url.searchParams.set('cfs', input.value);

        window.location = url;
    });
}
