import ajaxService from './services/ajaxService';

export default function collectionApi() {
    const elements = document.querySelectorAll('.js-collection-api');
    elements.forEach((container) => {
        let {endpoint} = container.dataset;
        const trigger = container.querySelector('.js-collection-api-trigger');
        const textfieldInput = container.querySelector('.js-collection-api-textfield input');

        trigger.addEventListener('click', (element) => {
            element.preventDefault();

            endpoint += textfieldInput.value;

            if (window.location.protocol === 'https:') {
                endpoint = endpoint.replace('http', 'https');
            }

            if (endpoint) {
                ajaxService.get(endpoint).then((res) => {
                    const url = new URL(window.location.toString());

                    url.searchParams.append('collectionId', res.collectionId);

                    window.location = url.toString();
                });
            }
        });
    });
}
