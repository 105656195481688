const $ = require('jquery');
require('jquery-pjax');

const SK_QR_OPTIONS_SHOW = 'qr/options/show';

export default function qrOptionsSwitch() {
    const elements = document.querySelectorAll('.qr__options-switch');

    let showQrCodeOptions = false;

    if (window.localStorage) {
        showQrCodeOptions = (window.localStorage.getItem(SK_QR_OPTIONS_SHOW) === 'true');
    }

    elements.forEach((element) => {
        if (showQrCodeOptions === true) {
            $('.qr__options').show();
        }

        element.addEventListener('click', () => {
            $('.qr__options').toggle();

            showQrCodeOptions = !showQrCodeOptions;

            if (window.localStorage) {
                window.localStorage.setItem(SK_QR_OPTIONS_SHOW, showQrCodeOptions === true ? 'true' : 'false');
            }
        });
    });
}
